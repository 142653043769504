<template>
  <div>
 <b-card
     no-body
     class="mb-0">


   <div class="m-2">

     <!-- Table Top -->
     <b-row>

       <!-- Per Page -->
       <b-col
           cols="12"
           md="8"
           class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
       >
         <label>每一页显示</label>
         <v-select
             v-model="page.pageSize"
             :options="perPageOptions"
             :clearable="false"

             class="per-page-selector d-inline-block mx-50"
         />
         <label>行数</label>
       </b-col>

       <!-- Search -->
       <b-col
           cols="12"
           md="4"
       >
         <div class="d-flex align-items-center justify-content-end">

           <b-button
               variant="primary"
                @click="saveSelect()"
               v-if="saveAffirm"
           >
             <span class="text-nowrap">全部提交</span>
           </b-button>
         </div>
       </b-col>
     </b-row>

   </div>

        <b-table
            striped
            hover
            responsive
            :items="items"
            :fields="fields"
            style="min-height:500px"
        >
          <template #cell(education_status_name)="data">
            <b-badge
                pill
                :variant="data.value.variant"
            >
              {{ data.value.value }}
            </b-badge>
          </template>

          <template #cell(lv)="row">
            <div v-if="quota_type === 0">
              {{ row.item.lv }}
            </div>
            <div v-if="quota_type === 1">
              <div v-if="row.item.finalist === 1">
                是
              </div>
              <div v-if="row.item.finalist === 0">
                否
              </div>

            </div>
          </template>

          <template #cell(pdf_url)="row">
            <b-button
                variant="outline-success"
                v-b-toggle.showRequestView
                @click="show_request(row.item.img_url,row.item.request_id)"
            >
              材料
            </b-button>
          </template>

          <template #cell(actions)="row">
            <b-row>
              <b-col>
                <b-button
                    v-if="affirm.includes(row.item.id) === false"
                    variant="success"
                    @click="addAffirm(row.item.id,row.index)"
                >
                  确认
                </b-button>
                <b-button
                    v-if="affirm.includes(row.item.id) === true"
                    variant="danger"
                    @click="delAffirm(row.item.id,row.index)"
                >
                  取消确认
                </b-button>
              </b-col>
            </b-row>
          </template>
        </b-table>
   <b-row class="m-2">
     <b-col
         cols="12"
     >
       <div class="d-flex align-items-center justify-content-end">

         <b-button
             variant="primary"
             class="mr-2"
             type="button"
             @click="selectAll()"

         >本页全选</b-button>

       </div>


     </b-col>
   </b-row>
     <b-row>

       <b-col
           cols="12"
       >
         <page
             :page="page"
             @getPage="getPage"
         />
       </b-col>

     </b-row>

 </b-card>

    <b-sidebar
        v-model="showStudentRequest"
        width="900px"
        hide-footer
        lazy
    >
      <b-card>
        <img-lists   :img-url="img_url" :request_id="request_id"></img-lists>
      </b-card>

    </b-sidebar>
  </div>
</template>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<script>
import {
  BBadge, BButton, BRow, BCol, BCard, BCardTitle, BCardBody, BSidebar, BTable, VBTooltip, VBToggle,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import 'animate.css'

import ImgLists from "@/views/awards/ImgLists.vue";
export default {
  name: "affirm",
  components: {
    ImgLists,
    BTable,
    BBadge,
    BCardTitle,
    BCardBody,
    BButton,
    BRow,
    BCard,
    BCol,
    vSelect,

    BSidebar
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,

  },
  data() {
    return {
      awards_id: Number(this.$route.query.awards_id),
      quota_type:Number(this.$route.query.quota_type),
      page: {
        currentPage: 1,
        totalRows: 0,
        pageSize:50,
      },
      perPageOptions : [10, 25, 50, 100],
      fields: [
        { key: 'grade', label: '年级' },
        { key: 'student_number', label: '学号' },
        { key: 'student_name', label: '姓名' },
        { key: 'sex', label: '性别', formatter: value => this.GetSex(value) },
        { key: 'speciality_name', label: '专业' },
        { key: 'education_status_name', label: '培养层次', formatter: value => this.StudentCultivateColor(value) },
        {key:"pdf_url",label: "调出材料"},
        { key: 'lv', label: '获奖等级/入围' },
        // { key: 'count_vote', label: '票数' },
        { key: 'actions', label: '操作' },
      ],
      items: [],
      affirm:[],
      saveAffirm:true,
      showStudentRequest:false,
      pdf_url:"",
      img_url:"",
      request_id:0,
    }
  },
  created() {
    this.getAwardsVote()
  },
  watch:{
    'page.pageSize':{
      handler(newVal,oldVal){
        this.getAwardsVote()
      }
    }
  },
  methods: {

    show_request(url,id){
      this.img_url =  url
      this.request_id = id
      this.showStudentRequest = true

    },

    getPage(page) {
      console.log(page)
      this.page.currentPage = page
      this.getAwardsVote()
    },
    getAwardsVote() {
      this.AwardsVoteLists({ awards_id: this.awards_id,page:this.page.currentPage,size:this.page.pageSize }).then(res => {
        console.log(res)
        this.items = res.data.data.lists
        this.page.totalRows = res.data.data.total_count
      })
    },

    addAffirm(id,index){
      this.affirm.push(id)
      //this.$set
    },
    delAffirm(id,index){
      this.affirm = this.affirm.filter(item => item !== id)
    },

    selectAll(){
      for (let i = 0; i < this.items.length; i++) {
        this.affirm.push(this.items[i].id)
      }
    },
    saveSelect(){

      this.$swal({
        title: '确定提交吗?',
        text: '您可以在确认好所有页学生的状态,一次性提交(即使您点击了下一页,系统仍然知道您在上页面的是否确认的操作,无需每一页都提交)',
        showCancelButton: true,
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.AwardsVoteSave({ awards_id: this.awards_id,lists:this.affirm }).then(res => {
            this.showToast(res.data.code,res.data.msg)
            if (res.data.code === 200){
              this.saveAffirm = false
            }
          })
        }
      })

    },

  },

}
</script>

<style scoped>

</style>
