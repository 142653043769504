<template>
  <div  style="min-height: 2000px">

    <section ref="print" >
      <b-row>
        <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center "
        >
          <h1><b>评奖申请表</b></h1>
        </b-col>
        <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center "
        >
          <p><b>业务受理编号:IMR{{ form.id }}</b></p>
        </b-col>
      </b-row>
      <div class="d-flex align-items-center justify-content-center">

        <table
            cellpadding="10"
            cellspacing="0"
            border="1"
        >

          <tr>
            <td
                rowspan="1"
                class="ft"
            >
              姓名
            </td>
            <td> {{ form.student_name }}</td>
            <td class="ft">
              性别
            </td>
            <td>
              {{ form.sex==1?"男":"女" }}
            </td>
            <td>联系电话</td>
            <td> {{ form.phone }} </td>
            <td rowspan="3">
              <img
                  :src="student_img "
                  width="130px"
                  height="140px"
              >
            </td>
          </tr>
          <tr>
            <td class="ft">
              学号
            </td>
            <td> {{ form.student_number }} </td>
            <td class="ft lt">
              层次
            </td>
            <td width="100px">
              {{ form.education_status_name }}
            </td>
            <td class="ft">
              年级
            </td>
            <td> {{ form.grade }} </td>

          </tr>
          <tr>
            <td
                colspan="1"
                class="ft"
            >
              政治面貌
            </td>
            <td colspan="2">
              {{ form.political_status_name }}
            </td>
            <td
                colspan="1"
                class="ft"
            >
              攻读方式
            </td>
            <td
                colspan="2"
            >
              {{ form.study_category_name }}
            </td>
          </tr>
          <tr>
            <td
                colspan="1"
                class="ft"
            >
              研究部
            </td>
            <td
                colspan="3"
            >
              {{ form.research_division_name }}
            </td>
            <td
                colspan="1"
                class="ft"
            >
              专业
            </td>
            <td colspan="2">
              {{ form.speciality_name }}
            </td>
          </tr>
          <tr>
            <td
                colspan="3"
                class="ft"
            >
              何时曾获过此奖项
            </td>
            <td
                colspan="4"
            >
              {{ form.whether_get }}
            </td>

          </tr>
          <tr>

            <td
                colspan="7"
                style="text-align:center "
                class="ft"
            >
              科研成果
            </td>
          </tr>

          <tr>
            <td
                colspan="1"
                class="ft"
            >
              作者次序
            </td>
            <td
                colspan="1"
                class="ft"
            >
              文章题目
            </td>
            <td
                colspan="1"
                class="ft"
            >
              期刊名
            </td>
            <td
                colspan="1"
                class="ft"
            >
              发表时间
            </td>
            <td
                colspan="1"
                class="ft"
            >
              期刊号
            </td>
            <td
                colspan="1"
                class="ft"
            >
              类型
            </td>
            <td
                colspan="1"
                class="ft"
            >
              备注
            </td>

          </tr>
          <tr
              v-for="(v,i) in this.form.achievement"
              :key="i"
          >
            <td colspan="1">
              {{ getSortName(v.author_sort) }}
            </td>
            <td
                colspan="1"
                class="lt"
            >
              {{ v.title }}
            </td>
            <td colspan="1">
              {{ v.journal_name }}
            </td>
            <td colspan="1">
              {{ v.time }}
            </td>
            <td colspan="1">
              {{ v.journal_number }}
            </td>
            <td colspan="1">
              {{ v.journal_type }}
            </td>
            <td colspan="1">
              {{ v.remark }}
            </td>

          </tr>
          <tr>
            <td
                height="200"
                class="ft"
            >
              个人介绍
            </td>
            <td colspan="6">
              {{ this.form.content }}
            </td>

          </tr>
          <tr>
            <td
                colspan="7"
                style="text-align:center "
                class="ft"
            >
              本人承诺，以上填写信息以及未展现部分所填写的所有内容真实有效,并且不包含涉密信息。
            </td>
          </tr>




        </table>
      </div>



    </section>
    <div v-for="(img,index) in imgLists">
         <b-img  :src="img" fluid alt="Responsive image" />
    </div>
  </div>
</template>

<script>
import {BCol, BImg, BRow} from 'bootstrap-vue'

export default {
  name: "ImgLists",
  components: {
    BImg,
    BRow,
    BCol,
  },
  props: {
    imgUrl: {
      type: String,
      default: ''
    },
    request_id: {
      type:Number,
    },
  },
  data () {
    return {
      imgLists:[],
      form:{},
      student_img: require('../../assets/images/avatars/user.jpeg'),
      sort: [{ id: 1, name: '一作' }, { id: 2, name: '共一作' }, { id: 3, name: '二作' }, { id: 4, name: '三作' }],
    };
  },
  created () {
    this.getStudentRequest()
    this.InitImgLists()

  },
  methods: {
    InitImgLists(){
      console.log(this.imgUrl)
      this.imgLists = JSON.parse(this.imgUrl)
      console.log(this.imgLists)
    },
    getStudentRequest() {
      this.AwardsRequestGet({ id: this.request_id }).then(res => {
        const { data } = res.data
        this.form = data
        console.log(this.form)
        if (data.achievement.length > 0){
          this.form.achievement = JSON.parse(data.achievement)
        }
      })
    },
    getSortName(id) {
      let str = ''
      for (let i = 0; i < this.sort.length; i++) {
        if (this.sort[i].id == id) {
          str = this.sort[i].name
        }
      }
      return str
    },
  }
}
</script>

<style scoped>
table {
  /*为表格设置合并边框模型*/
  border-collapse:collapse;
  /*列宽由表格宽度和列宽度设定*/

  table-layout:auto;
}
td {
  border:1px solid #ddd;
  /*允许在单词内换行。*/
  word-break:break-word;
  /*设置宽度*/
  width:150px;
  text-align: center
}
.ft{
  font-size: 15px;
  font-weight:bold
}
.lt{
  width: 200px;
}
</style>
